import React from 'react'
import { Card, Row, Col, Image, Button} from 'react-bootstrap'
import hero1 from '../assets/servicesHero.jpeg'
import { useAppContext } from '../AppContext';
import action from '../assets/action plan.png'
import specialize from '../assets/specialize.png'
import rectangle from '../assets/greenRectangle.png'
import square1 from '../assets/square1.svg'
import square2 from '../assets/square2.svg'
import square3 from '../assets/square3.svg'
import square4 from '../assets/square4.svg'
import choose from '../assets/whyChoose.png'
import square5 from '../assets/Hand Money.svg'
import square6 from '../assets/Diploma Verified.svg'
import square7 from '../assets/Scale.svg'
import square8 from '../assets/Hourglass.svg'
import ship from '../assets/ship-handling.png'
import service from '../assets/service.png'
import procure from '../assets/procure.png'
import procureMobile from '../assets/procure-mobile.png'
import npa from '../assets/NPA-LOGO.svg'
import nmdpra from '../assets/nmdpraLogo.svg'
import nimasa from '../assets/NIMASA-LOGO.svg'
import nuprc from '../assets/NUPRC-LOGO.svg'
import npaMobile from '../assets/NPA-LOGO.png'
import nmdpraMobile from '../assets/NMDPRA-LOGO.png'
import nimasaMobile from '../assets/NIMASA-LOGO.png'
import nuprcMobile from '../assets/NUPRC-LOGO.png'

const benefits = [
  {
    id:1,
    square:square5,
    title:"Cost Reductions",
    text:"Achieve significant savings through strategic sourcing and negotiation expertise",
  },
  {
    id:2,
    square:square6,
    title:"Quality Assurance",
    text:"Ensure high-quality supplies from trusted vendors",
  },
  {
    id:3,
    square:square7,
    title:"Streamlined Processes",
    text:"Reduce sourcing and transaction processing time",
  },
  {
    id:4,
    square:square8,
    title:"Real-Time Insights",
    text:"Gain visibility into procurement activities through interactive reporting tools",
  },
]



const services = [
  {
    id:1,
    square:square1,
    title:"Expertise",
    text:"Proven track record in procurement optimization",
  },
  {
    id:2,
    square:square2,
    title:"Innovation",
    text:"Leveraging technology for efficient procurement processes",
  },
  {
    id:3,
    square:square3,
    title:"Personalized Support",
    text:"Dedicated team for seamless collaboration",
  },
  {
    id:4,
    square:square4,
    title:"Cost Savings",
    text:"Significant reductions through strategic sourcing",
  },
]

const Services = () => {
  const {isMobile} = useAppContext();

  return (
  <div  className="scrollspy-content w-100 position-relative" style={{marginTop:'90px',overflowX:'hidden', backgroundColor:"rgb(0,11,25"}} >
  <Card className='' style={{borderRadius:'0px', border:'none'}}>
   <Card.Img src={hero1} alt='hero1' style={{minHeight:'330px',maxHeight:'550px', objectFit:'cover', borderRadius:'0px'}}/>
   <Card.ImgOverlay
className="d-flex flex-column ps-3 ps-md-5"
style={{
background: `linear-gradient(0deg, rgba(1, 62, 144, 0), rgba(1, 62, 144, 0)),
linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`

}}
>
  <div className='me-5 me-md-0 ps-lg-5 ms-lg-2 mt-xl-5 pt-4'>
       <h1 style={{color:'#E6F1FF', fontFamily:'Cinzel',fontSize:isMobile ? '32px':'68px' }} className='pt-xl-3 mb-3' >
          SERVICES
       </h1>
      <div style={{background: 'rgba(0, 34, 79, 0.4)',maxWidth:'40rem'
}} className=' p-2 p-md-3 text-white'>
  <h3 style={{fontSize:isMobile ? '17px':'32px', lineHeight:isMobile ? '25px':'40px', fontWeight:'700'
}}>
At Frebloom Marine Solutions, We Offer Robust Security Solutions That Are Finely Attuned To The Complex Nature Of Maritime Logistics And The Essential Requirement For Smooth, Uninterrupted Operations.</h3>
</div>
</div>
   </Card.ImgOverlay>

 </Card>
 <section className='px-4 px-md-5 text-white' style={{backgroundColor:"rgb(0,11,25"}}>
       
       <div className='px-lg-5 py-3 py-md-5 position-relative'  >
      
       <div className='d-block d-md-none position-absolute ' style={{top:'10%',right:'-95%'}}>
            <img src={action} alt='rectangle' style={{opacity:'0.3', height:'500px'}}/>
        </div>
        <Row className='d-flex align-items-center text-white pt-3'>
        <Col xs={12} md={6} className='h-100'>
               <h2 style={{fontSize:isMobile ? '28px':'43px'}}>Action Plan</h2>
               <div className=' d-flex flex-column gap-2 gap-md-3'>
          <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >We understand the challenges of maritime logistics, from security concerns to operational efficiency. Our team ensures your vessels are fully equipped and protected, minimizing downtime and maximizing operational success</h3>
          <h3 style={{fontWeight:'400', lineHeight:isMobile?'26px':'37px', fontSize:isMobile ? '17px':'22px'}}>By adhering to industry best practices, we strive to optimize our clients' maritime operations and maintain the highest standards of operational safety. The safety and security of our clients' assets and personnel are our foremost priorities. </h3>
          <h3 style={{fontWeight:'400', lineHeight:isMobile?'26px':'37px', fontSize:isMobile ? '17px':'22px'}}>Our expert team possesses extensive knowledge and experience to ensure the efficient flow of your operations, guaranteeing safe and timely service delivery. By leveraging our expertise and advanced technology, we provide tailored solutions specifically designed to meet the unique requirements of our clients. </h3>
        
          
          </div>
               </Col>
            <Col xs={12} md={6} className='h-100 d-none d-md-flex ' >
            <Image fluid  src={action} alt='hero2' style={{height:'100%'}} />
            </Col>
           
        </Row>
        </div>
        <div className='ps-lg-4 pe-lg-4 py-3 py-md-5 position-relative'  style={{overflowX:'hidden',overflowY:"hidden"}}>
        <div className='d-none d-md-block  position-absolute' style={{right:'0%', top:'9%'}}>
            <img src={rectangle} alt='rectangle' style={{maxHeight:'450px', maxWidth:'350px'}}/>
        </div>
      

      <Row className='d-flex align-items-center text-white pt-3 gap-0 gap-lg-4'>
      <Col xs={12} md={5} className='h-100 d-none d-md-flex ' >
          <Image fluid  src={specialize} alt='hero2' style={{height:'100%'}} />
          </Col>
      <Col xs={12} md={5} className='h-100 ps-xl-5'>
             <h2 style={{fontSize:isMobile ? '28px':'43px'}}>Our Specialization</h2>
             <div className=' d-flex flex-column gap-2 gap-md-3'>
        <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >Frebloom Marine Solutions Limited offers a range of specialized operations designed to
facilitate and enhance our clients’ activities in offshore environments, particularly in the oil and
gas sector.</h3>
        <h3 style={{fontWeight:'400', lineHeight:isMobile?'26px':'37px', fontSize:isMobile ? '17px':'22px'}}>We provide private maritime armed guards for client’s vessels
        operating in Nigerian waters to ensure the safety of the vessel and their crews </h3>
       
        
        </div>
             </Col>
         
         
      </Row>
      </div>
      <div className='px-lg-5 py-2 py-md-5 position-relative'  >
      
   
       <Row className='d-flex align-items-center text-white pt-3'>
       <Col xs={12} md={4} className='h-100 pe-xl-5 mb-3 mb-md-0'>
             
         <h1 style={{lineHeight:isMobile ? '32px':'65px', fontWeight:'700', fontSize:isMobile ? '28px':'50px', fontFamily:'Cinzel'}} >OUR SERVICES INCLUDE PROVISIONS OF:</h1>
        
       
         
         
              </Col>
           <Col xs={12} md={8} className='h-100 ' >
          <Row className='d-flex gap-5'>
          <Col xs={12} md={6} style={{border:"18px solid rgba(201, 249, 204, 0.4)"}} className='px-2 px-md-1 pt-3 d-none d-md-block'>
            <h3 style={{fontWeight:'500',color:'#98C4FF',fontSize:isMobile ? '17px':'20px'}}>OFFSHORE SUPPORT VESSELS</h3>
            <ul className='d-flex flex-column gap-2 mb-3'>
              <li>Security Escort Vessels (SEVs)</li>  
<li>Crew Boats</li>
<li>Surfer Boats</li>
<li>Tug and Terminal Support</li>
<li>Platform Supply Vessels</li>
<li>Barges Operations
</li>

            </ul>
            </Col>
            <Col xs={12} md={true} style={{border:"18px solid rgba(152, 196, 255, 0.4)"}} className='px-1 pt-3'>
            <div className='mb-1 d-block d-md-none'>
            <h3 style={{fontWeight:'500',color:'#98C4FF',fontSize:isMobile ? '17px':'20px'}}>OFFSHORE SUPPORT VESSELS</h3>
            <ul className='d-flex flex-column gap-2 mb-3'>
              <li>Security Escort Vessels (SEVs)</li>  
<li>Crew Boats</li>
<li>Surfer Boats</li>
<li>Tug and Terminal Support</li>
<li>Platform Supply Vessels</li>
<li>Barges Operations
</li>

            </ul>
            </div>
            <h3 style={{fontWeight:'500',color:'#98C4FF',fontSize:isMobile ? '17px':'20px'}}>SECURITY SERVICES</h3>
            <ul className='d-flex flex-column gap-2 mb-3'>
              <li>Onshore private security and logistics services</li>
<li>Stowaway search</li>
<li>Guard embarkation on clients’ vessels</li>

            </ul>
            </Col>
          </Row>
           </Col>
          
       </Row>
       </div>
      <div className='px-lg-5 py-2 py-md-5 position-relative'  >
      
      <div className='d-block d-md-none position-absolute ' style={{top:'15%',left:'-25%'}}>
           <img src={service} alt='rectangle' style={{opacity:'0.3', height:'200px'}}/>
       </div>
       <Row className='d-flex align-items-center text-white pt-3'>
       <Col xs={12} md={6} className='h-100'>
              <h2 style={{fontSize:isMobile ? '28px':'43px'}}>Manning Services</h2>
              <div className=' d-flex flex-column gap-2 gap-md-3'>
         <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >Frebloom Marine Solutions Limited handles all aspects of crew management and placement of qualified seafarers for various types of vessels. These include verifying certifications, arranging travel, and ensuring compliance with international maritime regulations.</h3>
        
       
         
         </div>
              </Col>
           <Col xs={12} md={6} className='h-100 d-none d-md-flex ' >
           <Image fluid  src={service} alt='hero2' style={{height:'100%'}} />
           </Col>
          
       </Row>
       </div>
      <div className='ps-lg-4 pe-lg-4 py-3 py-md-5 position-relative' style={{overflowX:'hidden',overflowY:"hidden"}}>
        <div className='d-none d-md-block  position-absolute' style={{right:'0%', top:'9%'}}>
            <img src={rectangle} alt='rectangle' style={{maxHeight:'450px', maxWidth:'350px'}}/>
        </div>
      

      <Row className='d-flex align-items-center text-white pt-3 gap-0 gap-lg-4'>
      <Col xs={12} md={5} className='h-100 d-none d-md-flex ' >
          <Image fluid  src={ship} alt='hero2' style={{height:'100%'}} />
          </Col>
      <Col xs={12} md={5} className='h-100 ps-xl-5 mb-3'>
             <h2 style={{fontSize:isMobile ? '28px':'43px'}}>Ship Chandling</h2>
             <div className=' d-flex flex-column gap-2 gap-md-3'>
        <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >Frebloom Marine Solutions Limited delivers vital services such as supply of provisions, equipment, and other necessary items to various vessels to ensure operational readiness of vessels and facilitate smooth turnover of supplies.</h3>
        
       
        
        </div>
             </Col>
             <Col xs={12} md={5} className='h-100 d-flex d-md-none ' >
          <Image fluid  src={procureMobile} alt='hero2' style={{height:'13rem', width:'95%'}} />
          </Col>
         
         
      </Row>
      </div>
      <div className='ps-lg-4 pe-lg-4 py-4 py-md-5 position-relative'  style={{overflowX:'hidden',overflowY:"hidden"}}>
      <div className='text-start text-md-center mb-2 mb-md-5'>
      <h2 style={{fontSize:isMobile ? '28px':'43px'}} >Procurement Services</h2>
      <h3 style={{fontSize:isMobile ? '20px':'27px', fontWeight:'500'}}> 
      Unlock Efficient Procurement with Frebloom Marine Solutions Limited</h3>
      </div>
      

      <Row className='d-flex align-items-center text-white pt-3 gap-0 gap-lg-4'>
      
      <Col xs={12} md={5} className='h-100 ps-xl-5 mb-3'>
            
             <div className=' d-flex flex-column gap-2 gap-md-3 pt-lg-3'>
        <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >At Frebloom Marine Solutions Limited, we deliver expert procurement services that enhance operational efficiency, reduce costs, and drive business success. Our innovative solutions empower clients to navigate procurement complexities with confidence.</h3>
        
       
        
        </div>
             </Col>
             <Col xs={12} md={5} className='h-100 d-none d-md-flex ' >
          <Image fluid  src={procure} alt='hero2' style={{height:'100%'}} />
          </Col>

             <Col xs={12} md={5} className='h-100 d-flex d-md-none ' >
          <Image fluid  src={procure} alt='hero2' style={{height:'13rem', width:'100%'}} />
          </Col>
         
         
      </Row>
      </div>

      
      <section className='px-lg-5 py-3 py-md-5 position-relative'>
        
       
        <h2 className='text-start text-md-center mb-4 mb-md-5' style={{fontSize:isMobile ? '24px': '40px'}}>Key Benefits</h2>
        
        
          
           <Row className='d-flex gap-0'>
            {benefits.map((benefit)=> (
   <Col xs={6} md={3} className='d-flex flex-column gap-2 mb-3  pe-lg-5' key={benefit?.id}>
   <div style={{background: '#E4FCE5', width:'64px',height:'64px', borderRadius:'16px',boxShadow: '0px 4px 4px 0px #00000040'
  
  }} className='d-flex justify-content-center align-items-center mb-1'>
   <img src={benefit?.square} alt='square'/>
   </div>
   <h5 style={{fontWeight:'700'}} className='mb-0 pb-0 mb-md-1'>{benefit?.title}</h5>
   <p style={{fontWeight:'400'}}>{benefit?.text}</p>
   </Col>
            ))}
           
           </Row>
              
  
       
          
        </section>
        </section>
        <section className='px-4 px-md-5 text-white position-relative' style={{backgroundColor:"rgb(0,11,25"}}>
        <div className='d-none d-md-block  position-absolute' style={{left:'-10%', top:'0%'}}>
            <img src={rectangle} alt='rectangle' style={{maxHeight:'450px', maxWidth:'350px'}}/>
        </div>
        <div className='ps-lg-4 pe-lg-2 py-3 py-md-5'>
        
      

      <Row className='d-flex align-items-center text-white'>
      <Col xs={12} md={5} className='h-100 ps-xl-5 mb-3' >
      <h2 className='' style={{fontSize:isMobile ? '24px': '40px'}}>How We Deliver</h2>
            
        <h3 style={{lineHeight:isMobile?'26px':'37px', fontWeight:'400', fontSize:isMobile ? '17px':'22px'}} >Our team of experienced procurement professionals utilizes cutting-edge technology and market intelligence to deliver tailored solutions. We work closely with clients to understand their unique needs and provide personalized support.
        </h3>
          </Col>
      <Col xs={12} md={7} className='h-100 ps-xl-5 mb-lg-3'>
      <h2 className='' style={{fontSize:isMobile ? '24px': '40px'}}>Our Procurement Expertise</h2>
      <h3 style={{fontWeight:'500',fontSize:isMobile?'18px':'22px'}}>
      We provide comprehensive procurement services, including :</h3>
             <ul className=' d-flex flex-column gap-2 mt-3' style={{fontSize:isMobile?'18px':'22px', fontWeight:'600'}}>
       <li>Strategic Sourcing</li>
<li>Supplier Management</li>
<li>Contract Negotiation</li>
<li>Procurement Process Optimization</li>
<li>Supply Chain Management</li>
       
       
        
        </ul>
             </Col>
         
         
      </Row>
      </div>
      </section>
      <section className='px-4 px-md-5 text-white' style={{backgroundColor:"rgb(0,11,25"}}>
      <section className='px-lg-5 py-3 py-md-5 position-relative'>
        
       
        <h2 className='text-start text-md-center mb-4 mb-md-5' style={{fontSize:isMobile ? '24px': '40px'}}>Why Choose Frebloom Marine Solutions Limited</h2>
        <div className='d-block d-md-none position-absolute ' style={{top:'10%',right:'-72%'}}>
              <img src={choose} alt='rectangle' style={{opacity:'0.3', height:'410px'}}/>
          </div>
        
          <Row className='d-flex align-items-center'>
          
              <Col xs={12} md={6} className='px-lg-3 h-100 '>
           <Row className='d-flex gap-0'>
            {services.map((service)=> (
   <Col xs={6} className='d-flex flex-column gap-2 mb-3  pe-lg-5' key={service?.id}>
   <div style={{background: '#E4FCE5', width:'64px',height:'64px', borderRadius:'16px',boxShadow: '0px 4px 4px 0px #00000040'
  
  }} className='d-flex justify-content-center align-items-center mb-1'>
   <img src={service?.square} alt='square'/>
   </div>
   <h5 style={{fontWeight:'700'}} className='mb-0 pb-0 mb-md-1'>{service?.title}</h5>
   <p style={{fontWeight:'400'}}>{service?.text}</p>
   </Col>
            ))}
           
           </Row>
              </Col>
              <Col xs={12} md={6} className='d-none d-md-flex mt-0 pt-0' >
              <Image fluid src={choose} alt='why choose frebloom' style={{height:'100%'}} />
              </Col>
          </Row>
  
          <div className='text-center my-3'>    <Button className='mb-4' href='/contact'>GET IN TOUCH <i className="bi bi-arrow-right"></i></Button>
          
          <h3 style={{fontSize:isMobile ? '19px': '27px'}}>Discover how our services can transform your operations</h3>
          </div>
          
        </section> 
       

  

      </section>
      <section className='pb-2 d-flex justify-content-center'>
      <img src={isMobile ? npaMobile : npa} alt='authority' className=' pe-2 pe-md-4 authority'/>
  <img src={isMobile ? nimasaMobile : nimasa} alt='authority' className=' pe-2 pe-md-4 authority'/>

  <img src={isMobile ? nuprcMobile : nuprc} alt='authority' className='authority'  />

  <img src={isMobile ? nmdpraMobile : nmdpra} alt='authority' className='authority' />
 </section>
      
    </div>
  )
}

export default Services