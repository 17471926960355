
import './styles.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { BrowserRouter,Routes,Route,Navigate, useLoaderData, useLocation } from 'react-router-dom';
import GuestNavbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';

import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import { useAppContext } from './AppContext';
import { useEffect } from 'react';
import Mark from 'mark.js';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
  const {isMobile,searchQuery, setSearchQuery,isSearching, setIsSearching,matches, setMatches,currentIndex, setCurrentIndex,markRef}= useAppContext();
  const location = useLocation();
   const clearHighlights = () => {
    const instance = new Mark(document.body);
    instance.unmark();
    setMatches([]);
    setCurrentIndex(0);
  };
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
        performSearch(query);
    } else {
        clearHighlights();
    }
  };
  
  // Highlight all matches and store references to each one
  const performSearch = (query) => {
    const instance = new Mark(document.body);
    instance.unmark(); // Clear previous highlights
    
    const newMatches = [];
    instance.mark(query, {
      separateWordSearch: false,
      each: (element) => { // This callback fires for each match
        newMatches.push(element);
      },
      done: () => {
        setMatches(newMatches);
        setCurrentIndex(0);
        // if (newMatches.length > 0) {
        //   scrollToMatch(0);
        // }
      }
    });
  };
  
  const scrollToMatch = (index) => {
    if (matches.length > 0) {
      const match = matches[index];
      if (match) {
        match.scrollIntoView({ behavior: 'smooth', block: 'center' });
        match.style.backgroundColor = '#ffd54f'; // Highlight current match
      }
    }
  };

  useEffect(() => {
    if (matches.length > 0) {
      scrollToMatch(0); // Scroll to the first match
    }
  }, [matches]);
  
  
  // Handle Next button
  const handleNext = () => {
    if (matches.length > 0) {
        const nextIndex = (currentIndex + 1) % matches.length;
        setCurrentIndex(nextIndex);
        scrollToMatch(nextIndex);
    }
  };
  
  // Handle Previous button
  const handlePrevious = () => {
    if (matches.length > 0) {
        const prevIndex = (currentIndex - 1 + matches.length) % matches.length;
        setCurrentIndex(prevIndex);
        scrollToMatch(prevIndex);
    }
  };
  
 

  // New function to handle the cancel button click
  const handleCancelSearch = () => {
    setSearchQuery('');
    clearHighlights();
    setIsSearching(false);
  };
  
 
  return (
    <div className="my-app position-relative">
      {isSearching && (
                <div style={{ position: 'fixed', top: isMobile ?'100px':'120px', right: '12px', zIndex: '2000', backgroundColor: 'white',boxShadow: '0px 0px 32px 8px #00000040', borderRadius:"8px" }} className='p-2 d-flex gap-2 align-items-center'>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        style={{ padding: '5px' }}
                    />
                    <button onClick={handlePrevious} style={{border:'none', backgroundColor:'transparent'}}><i className='bi bi-chevron-up' style={{fontSize:'1.5rem'}}></i></button>
                    <button onClick={handleNext} style={{border:'none', backgroundColor:'transparent'}}><i className='bi bi-chevron-down'  style={{fontSize:'1.5rem'}}></i></button>
                    {matches.length > 0 &&
                    <span className='p-2' style={{borderRadius:'10px', border:'1px solid #000040'}}>
  {matches.length > 0 && `${currentIndex + 1}/${matches.length}` }
</span>}
<button onClick={handleCancelSearch} style={{ border: 'none', backgroundColor: 'transparent' }}>
            <i className='bi bi-x-circle' style={{ fontSize: '1.5rem', color: 'red' }}></i>
          </button>
                </div>
            )}
    
   
    <GuestNavbar/>
    
   <ScrollToTop/>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/services" element={<Services />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <Footer/>
   
    </div>
  );
}

export default App;
