import { createContext, useState, useEffect, useContext, useRef } from "react";


export const AppContext = createContext();

export const AppContextProvider = (props) => {
 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [matches, setMatches] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const markRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const contextValue = { isMobile, setIsMobile,searchQuery, setSearchQuery,isSearching, setIsSearching,matches, setMatches,currentIndex, setCurrentIndex,markRef};

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};
