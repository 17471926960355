import React, { useState, useEffect } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import companyLogo from '../assets/logo.svg'
import search from '../assets/search.svg'
import {  NavLink} from 'react-router-dom'
import {  FaTimes } from 'react-icons/fa';
import bar from '../assets/toggle-bar.svg'
import { useAppContext } from '../AppContext'
import Mark from 'mark.js';

const GuestNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const {isSearching, setIsSearching,setSearchQuery,setMatches,setCurrentIndex}= useAppContext()
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearHighlights = () => {
    const instance = new Mark(document.body);
    instance.unmark();
    setMatches([]);
    setCurrentIndex(0);
  };
  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchQuery(''); // reset search query on open/close
    clearHighlights();
};


  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    setExpanded(false);
    
  };

  

 
  return (
    <>
    <Navbar expand='md' style={{overflowX:'hidden', backgroundColor:'#ffffff',boxShadow: "0px 0px 8px 0px #00000080", height:isMobile && !expanded &&'90px'
}} className='px-3 py-0 my-0' fixed='top' expanded={expanded} >
        <Navbar.Brand href='/' className='ms-xl-3 '  onClick={closeNavbar}>
        <img src={companyLogo} alt='logo' className='fb-logo' style={{width: isMobile ? '80px':'100px', height:isMobile? '80px':'100px', objectFit:'cover'}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}>
        {expanded ? <FaTimes /> : <img src={bar} alt='toggle' style={{height:'50px'}} />} 
      </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-md-auto gap-xl-4 gap-lg-3 gap-2 mt-0 mt-md-2" id="navbar-example2">

        <NavLink className='nav-link' activeclassname="active" to='/' onClick={closeNavbar}>Home</NavLink>
        <NavLink className='nav-link' to='/about' onClick={closeNavbar}> About Us</NavLink>
            <NavLink className='nav-link' to='/services' onClick={closeNavbar}> Services</NavLink>
           
         
            <NavLink className='nav-link' activeclassname="active" to='/contact' onClick={closeNavbar}> Contact</NavLink>
        </Nav>
        <Nav className='d-flex me-xl-3 my-3 my-md-0' id="navbar-example2">
         
        <img src={search} alt='search'   style={{ width: '35px', cursor: 'pointer' }}
        onClick={()=>{toggleSearch();closeNavbar()}} />
        </Nav>
      
        </Navbar.Collapse>
      
    </Navbar>
   
    </>
  )
}

export default GuestNavbar
