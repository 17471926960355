import React from 'react'
import { Card,Button,Row,Col, Image } from 'react-bootstrap'
import hero1 from '../assets/hero-1-1.png'
import rectangle from '../assets/Rectangle.png'
import hero2 from '../assets/hero-2-1.png'
import hero3 from '../assets/hero-3.png'

import missionBg from '../assets/mission-bg.png'
import vision from '../assets/vision-icon.svg'
import mission from '../assets/mission-icon.svg'
import npa from '../assets/NPA-LOGO.svg'
import nmdpra from '../assets/nmdpraLogo.svg'
import nimasa from '../assets/NIMASA-LOGO.svg'
import nuprc from '../assets/NUPRC-LOGO.svg'
import npaMobile from '../assets/NPA-LOGO.png'
import nmdpraMobile from '../assets/NMDPRA-LOGO.png'
import nimasaMobile from '../assets/NIMASA-LOGO.png'
import nuprcMobile from '../assets/NUPRC-LOGO.png'
import square1 from '../assets/square1.svg'
import square2 from '../assets/square2.svg'
import square3 from '../assets/square3.svg'
import square4 from '../assets/square4.svg'

import { useAppContext } from '../AppContext'

const services = [
  {
    id:1,
    square:square1,
    title:"Enhanced Operational Efficiency",
    text:"By leveraging cutting-edge technology and industry best practices, we strive to optimize our clients’ maritime operations, reducing costs and increasing productivity.",
  },
  {
    id:2,
    square:square2,
    title:"Promote Sustainability",
    text:"We are dedicated to minimizing our environmental impact and supporting long-term responsible growth through sustainable practices and innovative solutions.",
  },
  {
    id:3,
    square:square3,
    title:"Ensure Safety and Security",
    text:"We prioritize the safety and security of our clients’ assets and personnel, offering robust security solutions and maintaining the highest standards of operational safety.",
  },
  {
    id:4,
    square:square4,
    title:"Strong Partnerships",
    text:"We believe in building long-lasting relationships with our clients, based on trust, transparency, and mutual success.",
  },
]

const Home = () => {
    const {isMobile} = useAppContext();
   
  return (
    <div className="scrollspy-content" style={{marginTop:'90px'}} >
      <Card className='mb-4' style={{borderRadius:'0px'}}>
        <Card.Img src={hero1} alt='hero1' style={{minHeight:'300px',maxHeight:'550px', objectFit:'cover', borderRadius:'0px'}}/>
        <Card.ImgOverlay
  className="px-3 px-md-5"
  style={{
    background: `
      linear-gradient(0deg, rgba(1, 62, 144, 0.25), rgba(1, 62, 144, 0.25)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))
    `,
  }}
>
     <div className='d-flex flex-column gap-2 px-md-5 align-items-start mt-xl-5 pt-5 mx-md-5 '
>            <h1 style={{color:'#fff', fontFamily:'Cinzel',lineHeight:isMobile ? '40px':'90px' }} className='ms-lg-5'>
                FREBLOOM
                <br/>
                MARINE
                <br/>
                SOLUTIONS
            </h1>
            <Button className='ms-lg-5 d-flex gap-3' href="mailto:info@frebloommarine.com"  target="_blank"  
            rel="noopener noreferrer" >CONTACT US<i className="bi bi-arrow-right"></i></Button>
            </div>
        </Card.ImgOverlay>
      </Card>
      <section className='about-us-section position-relative' style={{overflowX:'hidden',overflowY:"hidden"}}>
        <div className='about-img-position position-absolute '>
            <img src={rectangle} alt='rectangle'/>
        </div>
       
      

        <Row className='content-container'>
            <Col xs={12} md={5} className='h-100 d-none d-md-flex ' style={{paddingLeft:'40px'}}>
            <Image fluid src={hero2} alt='hero2' style={{height:'100%'}} />
            </Col>
            <Col xs={12} md={7} className='h-100'>
                <div className='ps-xl-3 d-flex flex-column gap-2 gap-md-3'>
          <h4 style={{color:'#000B19', lineHeight:isMobile?'26px':'30px', fontWeight:'700'}} >Frebloom Marine is a dynamic player in Nigeria's maritime and oil and gas sectors. We provide offshore support, safeguarding our clients' vessels, especially in the Gulf of Guinea.</h4>
          <h5 style={{color:'#000B19', fontWeight:'400', lineHeight:isMobile?'26px':'30px'}}>As a stakeholder-centric organization, we prioritize transparent communication and deliver measurable results to meet our stakeholders’ expectations. </h5>
          <h5 style={{color:'#000B19', fontWeight:'400', lineHeight:isMobile?'26px':'30px'}}>By adhering to international best practices and upholding high ethical standards, we strive to foster trust and collaboration with all our stakeholders, paving the way for sustainable success. </h5>
        
          <div className='text-start w-auto'>    <Button className='' href='/about'>READ MORE <i className="bi bi-arrow-right"></i></Button></div>
          </div>
            </Col>
        </Row>
      </section>
      <section className='mb-4' style={{zIndex:'99'}}>

      <Card.ImgOverlay style={{
      backgroundImage: `url(${missionBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      position: 'relative',
      zIndex:'99',
  height:'100%', width:'100'}} className='px-3 px-lg-5 py-4 py-md-5'>
        <Row className=''>
            <Col xs={12} md={6} className='mb-4'>

           <div className='d-flex flex-column gap-2 text-start text-md-center'>
                <div><img src={vision} alt='vision'/></div>
                <h4 style={{color:'#013E90', fontWeight:'700'}}className='mb-0'>VISION</h4>
                <h5 style={{color:'#00224F',lineHeight:isMobile ?'24px':'30px'}} >To lead in providing of innovative, cost-effective maritime solutions.</h5>
            </div>
            </Col>

            <Col xs={12} md={6} className='mb-md-4'>
                <div
            className='d-flex flex-column gap-2  text-start text-md-center'>
               <div><img src={mission} alt='mission'/></div> 
                <h4  className='mb-0'style={{color:'#013E90', fontWeight:'700'}}>MISSION</h4>
                <h5 style={{color:'#00224F',lineHeight:isMobile ?'24px':'30px'}}>Our mission is to deliver tailored maritime solutions addressing our clients’ unique challenges and driving sustainable development in the regions we serve.</h5>
                </div>
            </Col>
        </Row>
        </Card.ImgOverlay>
      </section>
      <section className='position-relative offer-section'   style={{marginTop:'0px'}}>
        
       
      <h2 style={{color:'#000B19'}} className='text-center mb-4 mb-md-5'>What We Offer</h2>


        <Row className='d-block d-md-flex align-items-center'>
        <Col xs={12} md={5} className='d-flex d-md-none mb-3' >
            <Image fluid src={hero3} alt='hero2' style={{height:'100%'}} />
            </Col>
            <Col xs={12} md={7} className='px-lg-3 h-100 '>
         <Row>
          {services.map((service)=> (
 <Col xs={12} md={6} className='d-flex flex-column gap-2 mb-3 text-center text-md-start' key={service?.id}>
 <div style={{background: '#E4FCE5', width:'64px',height:'64px', borderRadius:'16px',boxShadow: '0px 4px 4px 0px #00000040'

}} className='d-flex justify-content-center text-center align-items-center mb-1 mx-auto mx-md-0'>
 <img src={service?.square} alt='square'/>
 </div>
 <h5 style={{fontWeight:'700'}}>{service?.title}</h5>
 <p style={{fontWeight:'400'}}>{service?.text}</p>
 </Col>
          ))}
         
         </Row>
            </Col>
            <Col xs={12} md={5} className='d-none d-md-flex' >
            <Image fluid src={hero3} alt='hero2' style={{height:'100%'}} />
            </Col>
        </Row>
      </section>
      <section style={{backgroundColor:'#ffffff'}} className='py-2 d-flex justify-content-center'>
      <img src={isMobile ? npaMobile : npa} alt='authority' className=' pe-2 pe-md-4 authority'/>
  <img src={isMobile ? nimasaMobile : nimasa} alt='authority' className=' pe-2 pe-md-4 authority'/>

  <img src={isMobile ? nuprcMobile : nuprc} alt='authority' className='authority'  />

  <img src={isMobile ? nmdpraMobile : nmdpra} alt='authority' className='authority' />
 </section>
      
    </div>
  )
}

export default Home
// ns1.dns-parking.com

// ns2.dns-parking.com