import React, { useState } from 'react'
import hero1 from '../assets/contact-hero.jpg'
import address from '../assets/address-icon.svg'
import ufo from '../assets/ufo-icon.svg'
import rocket from '../assets/rocket.svg'
import { Card,Form, FloatingLabel,Button,Row,Col } from 'react-bootstrap'
import emailjs from '@emailjs/browser'
import npa from '../assets/NPA-LOGO.svg'
import nmdpra from '../assets/nmdpraLogo.svg'
import nimasa from '../assets/NIMASA-LOGO.svg'
import nuprc from '../assets/NUPRC-LOGO.svg'
import npaMobile from '../assets/NPA-LOGO.png'
import nmdpraMobile from '../assets/NMDPRA-LOGO.png'
import nimasaMobile from '../assets/NIMASA-LOGO.png'
import nuprcMobile from '../assets/NUPRC-LOGO.png'
import { useAppContext } from '../AppContext'

const Contact = () => {
  
  const [loading, setLoading] = useState(false);
   const {isMobile} = useAppContext()

  const sendEmail = (e) => {
      e.preventDefault();

      setLoading(true)
  
     
      const formData = new FormData(e.target);
      const templateParams = {
        from_name: formData.get('name'),
        from_email: formData.get('email'),
        message: formData.get('message'),
      };
    
      emailjs
        .send('service_2frlbth', 'template_3rw14di', templateParams, 'i7KHpRPzfy0Q9WyR_')
        .then((response) => {
          setLoading(false)
        
          alert('Message sent successfully')
          e.target.reset();
         
        })
        .catch((error) => {
          setLoading(false)
       console.log(error)
          alert('Failed to send message. Please try again')

        });
        
    };
  return (
    <div  className="scrollspy-content w-100 position-relative mb-lg-2" style={{marginTop:'90px',overflowX:'hidden'}} >
       <Card className='mb-4 mb-lg-5' style={{borderRadius:'0px'}}>
        <Card.Img src={hero1} alt='hero1' style={{minHeight:'240px',maxHeight:'550px', objectFit:'cover', borderRadius:'0px'}}/>
        <Card.ImgOverlay
  className="d-flex align-items-center ps-4 ps-md-5"
  style={{
    background: `
      linear-gradient(0deg, rgba(1, 62, 144, 0.1), rgba(1, 62, 144, 0.1)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))
    `,
  }}
>
            <h1 style={{color:'#E6F1FF', fontFamily:'Cinzel', }} className='ps-lg-5 ms-lg-5'>
               GET IN TOUCH
            </h1>
           
            
        </Card.ImgOverlay>

      </Card>
      <Row className='d-flex gap-lg-5 px-md-5 mx-lg-5'>
      <Col xs={12} md={true} className='mb-4 h-100'>
      {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
    
    <Card className='border-0 px-3 pt-3 pb-2' style={{borderRadius:'0px', backgroundColor:'#E4FCE5'}}><div  className='ps-1'><h2 style={{fontWeight:'700'}} className='mb-0 pb-0'>Enquiry Form -</h2></div>
<Form className='mt-4 mb-4' onSubmit={sendEmail} >
<FloatingLabel controlId="floatingInput" label="Name" className="mb-0" style={{fontWeight:'700'}}>
      <Form.Control
        type="text"
        name="name"
        placeholder="name"
        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderRadius:'0px', borderBottom: '1px solid #000000', backgroundColor: '#E4FCE5' }}
        required
      />
    </FloatingLabel>

    <FloatingLabel controlId="floatingInput" label="Email" className="mb-0" style={{fontWeight:'700'}}>
      <Form.Control
        type="email"
        name="email"
        placeholder="name@example.com"
        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderRadius:'0px', borderBottom: '1px solid #000000', backgroundColor: '#E4FCE5' }}
        required
      />
    </FloatingLabel>

    <FloatingLabel controlId="floatingTextarea" label="Message" className="mb-0" style={{fontWeight:'700'}}>
  <Form.Control
    as="textarea"
    placeholder="Type your message here"
    name="message"
    rows={5}
    style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderRadius:'0px', borderBottom: '1px solid #000000', backgroundColor: '#E4FCE5', height:'140px' }} 
    required
  />
</FloatingLabel>


<div className='mt-4 w-100'>
      <Button  type='submit' className='w-100'>
   SUBMIT &nbsp; <i className="bi bi-arrow-right"></i>
  </Button>
  </div>

</Form>
</Card>

      }</Col>
      <Col xs={12} md={5}  className='mb-4 ps-lg-5 h-100'>
<Card style={{boxShadow: '0px 0px 32px 8px #00000040', borderRadius:'0px'
}} className='border-0 d-flex flex-column gap-3 p-3 p-lg-5 h-100'>

    <h2 className='mb-3'>Our Info</h2>

    <div className='d-flex gap-3 align-items-start' >
<img src={ufo} alt='icon'/>
<div>
    <h4 style={{fontWeight:'700'}}>Email</h4>
    <a href="mailto:info@frebloommarine.com"  target="_blank"  
            rel="noopener noreferrer" className='text-dark'> <h5  className='ms-auto'>info@frebloommarine.com</h5></a>
</div>
    </div>
    <div className='d-flex gap-3 align-items-start'>
<img src={rocket} alt='icon'/>
<div>
    <h4 style={{fontWeight:'700'}}>Phone Number</h4>
    <a href="tel:+2349010373733" className='text-dark'><h5 className='ms-auto'>+234 (0) 90 1037 3733</h5></a> 
</div>
    </div>
    <div className='d-flex gap-3 align-items-start'>
<img src={address} alt='icon'/>
<div>
    <h4 style={{fontWeight:'700'}}>Address</h4>
    <h5 style={{lineHeight:'26px'}}>No. 5 Agbor Close, Ajao Estate Off International Airport Road, Lagos, Nigeria</h5>
</div>
    </div>
</Card>
</Col>
</Row>
<section style={{backgroundColor:'#ffffff'}} className='py-2 d-flex justify-content-center mt-2'>
<img src={isMobile ? npaMobile : npa} alt='authority' className=' pe-2 pe-md-4 authority'/>
  <img src={isMobile ? nimasaMobile : nimasa} alt='authority' className=' pe-2 pe-md-4 authority'/>

  <img src={isMobile ? nuprcMobile : nuprc} alt='authority' className='authority'  />

  <img src={isMobile ? nmdpraMobile : nmdpra} alt='authority' className='authority' />
 </section>
    </div>
  )
}

export default Contact
