import React from 'react'
import { Card,Button,Row,Col, Image } from 'react-bootstrap'
import hero1 from '../assets/about-hero-2.png'
import { useAppContext } from '../AppContext'
import rectangle from '../assets/Rectangle.png'
import mission from '../assets/missiom.png'
import vision from '../assets/vision.png'
import missionMobile from '../assets/mission-mobile.svg'
import visionMobile from '../assets/vision-mobile.svg'
import innovation from '../assets/Suitcase Tag.svg'
import integrity from '../assets/Diploma.svg'
import sustainability from '../assets/Graph New Up.svg'
import respect from '../assets/Hand Heart.svg'
import excellence from '../assets/Star Rings.svg'
import authority from '../assets/authority.svg'
import authorityMobile from '../assets/authority-mobile.svg'
import npa from '../assets/NPA-LOGO.svg'
import nmdpra from '../assets/nmdpraLogo.svg'
import nimasa from '../assets/NIMASA-LOGO.svg'
import nuprc from '../assets/NUPRC-LOGO.svg'
import npaMobile from '../assets/NPA-LOGO.png'
import nmdpraMobile from '../assets/NMDPRA-LOGO.png'
import nimasaMobile from '../assets/NIMASA-LOGO.png'
import nuprcMobile from '../assets/NUPRC-LOGO.png'


const values = [
  {
    id:1,
    square:innovation,
    title:"Innovation",
    text:"Continuous investment in training and development.",
  },
  {
    id:2,
    square:integrity,
    title:"Integrity",
    text:"Conducting business with honesty and accountability",
  },
  {
    id:3,
    square:sustainability,
    title:"Sustainability",
    text:"Committed to reducing environmental impact and promoting sustainable practices",
  },
  {
    id:4,
    square:respect,
    title:"Respect",
    text:"Valuing diversity and fostering an inclusive work environment",
  },
  {
    id:5,
    square:excellence,
    title:"Excellence",
    text:"Pursuing relentless improvements to provide the highest level of service ",
  },
]


const About = () => {
  const {isMobile} = useAppContext()
  return (
    <div  className="scrollspy-content" style={{marginTop:'90px'}} >
       <Card className='mb-4' style={{borderRadius:'0px'}}>
        <Card.Img src={hero1} alt='hero1' style={{minHeight:'240px',maxHeight:'550px', objectFit:'cover', borderRadius:'0px'}}/>
        <Card.ImgOverlay
  className="d-flex align-items-center ps-4 ps-md-5"
  style={{
    background: `linear-gradient(0deg, rgba(1, 62, 144, 0.1), rgba(1, 62, 144, 0.1)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`
    
    }}
>          <div className='ps-xl-5 ms-xl-5'>
            <h1 style={{color:'#E6F1FF', fontFamily:'Cinzel', }} className='ps-lg-5 ms-lg-5 mt-xl-5'>
               ABOUT US
            </h1>
            </div>
            
        </Card.ImgOverlay>
      </Card>
      <div className=' px-3 px-lg-5 mx-xl-5 pb-3 pb-md-4'>
      <section className='ps-xl-5 pe-xl-5 my-4 my-md-5  position-relative' style={{overflowX:'hidden',overflowY:"hidden"}}>
        <div className='d-block d-md-none about-img-position position-absolute '>
            <img src={rectangle} alt='rectangle'/>
        </div>
       
      
           
                <div className=' d-flex flex-column gap-2 gap-md-3' style={{marginRight: isMobile ? '0px':'130px'}} >
          <h4 style={{color:'#013070', lineHeight:isMobile?'23px':'30px', fontWeight:'700'}} >Frebloom Marine is a dynamic player in Nigeria's maritime and oil and gas sectors. We provide offshore support services, safeguarding our clients' vessels especially in the Gulf of Guinea</h4>
          <h5 style={{color:'#000B19', fontWeight:'400', lineHeight:isMobile?'23px':'30px'}}>As a stakeholder-centric organization, we prioritize transparent communication and deliver measurable results to meet our stakeholders’ expectations. By adhering to international best practises and upholding high ethical standards, we strive to foster trust and collaboration with all our stakeholders, paving the way for sustainable success. </h5>
          <h5 style={{color:'#000B19', fontWeight:'400', lineHeight:isMobile?'23px':'30px'}}>We offer a comprehensive range of solutions tailored to the unique needs of our clients in the oil and gas industry. Our offerings include offshore security services, ship chandling, vessel management and crewing services for the offshore oil and gas industry.</h5>
          <h5 style={{color:'#000B19', fontWeight:'400', lineHeight:isMobile?'23px':'30px'}}>Embracing a safety-first philosophy, Frebloom Marine places a strong emphasis on optimizing stakeholder satisfaction as a core aspect of our corporate culture. This dedication empowers us to consistently exceed the expectations of all those we serve</h5>
        
          <div className='text-start w-auto'>    <Button className='' href='/contact'>CONTACT US <i className="bi bi-arrow-right"></i></Button></div>
          </div>
            
      </section>
      <section className=' pt-2 pt-md-0 my-3 my-md-5  position-relative' style={{overflowX:'hidden',overflowY:"hidden"}}>
        <div className='d-flex d-md-none border-0 py-2 pe-2 ps-3 m-1 ' style={{boxShadow: '0px 0px 8px 0px #00000080', height:'235px'}}>    <div  className='d-flex align-items-center gap-2'>
        <div  className=''>
          <h1 style={{fontSize:'27px', color:'#013E90',fontWeight:'700'}}>Vision</h1>
          <h3 style={{color:'#00224F', fontWeight:'400', fontSize:'16px'}}>To lead in providing of innovative cost-effective maritime solutions. </h3>
          </div>
         
            <img  src={visionMobile} alt='vision' />
            </div>
         
        
        </div>
        <Row className='d-none d-md-flex align-items-center gap-5'>
          <Col md={6}>
            <Image fluid src={vision} alt='vision' />
          </Col>
          <Col md={true} className=''>
          <h1 style={{fontSize:'56px', color:'#013E90',fontWeight:'700'}}>Vision</h1>
          <h3 style={{color:'#00224F', fontWeight:'700', fontSize:'22px'}}>To lead in providing of innovative cost-effective maritime solutions. </h3>
          </Col>
        </Row>
      </section>
      <section className=' my-3 my-md-5  position-relative'  style={{overflowX:'hidden',overflowY:"hidden"}}>
      <div className='d-flex d-md-none mb-3 py-2 ps-0 pe-2 m-1'  style={{boxShadow: '0px 0px 8px 0px #00000080',height:'235px'}}>    <div  className='d-flex align-items-center gap-1'>
   <img  src={missionMobile} alt='vision' />
        <div  className='px-0'>
          <h1 style={{fontSize:'27px', color:'#013E90',fontWeight:'700'}}>Mission</h1>
          <h3 style={{color:'#00224F', fontWeight:'400', fontSize:'16px'}}>Our mission is to deliver tailored maritime solutions, addressing our clients’ unique challenges and driving sustainable development in the regions we serve.</h3>
          </div>
         
           
            </div>
         
        
        </div>
        <Row className='d-none d-md-flex align-items-center gap-4 '>
        <Col md={5} className=''>
          <h1 style={{fontSize:'56px', color:'#013E90',fontWeight:'700'}}>Mission</h1>
          <h3 style={{color:'#00224F', fontWeight:'700', fontSize:'22px', lineHeight:'30px'}}>Our mission is to deliver tailored maritime solutions, addressing our clients’ unique challenges and driving sustainable development in the regions we serve. </h3>
          </Col>
          <Col md={true}>
            <Image fluid src={mission} alt='Mission' />
          </Col>
          
        </Row>
      </section>
 <section className='pb-2 pb-md-0 my-3 my-md-5 ps-lg-5  position-relative'>
 <div className='d-flex gap-2 align-items-center justify-content-center mb-2 mb-lg-5'>
 <div style={{width:'50px', border:'2px solid #013E90'}}></div><h1 style={{color:'#013E90'}}>Our Values</h1><div style={{width:'50px', border:'2px solid #013E90'}}></div>
 </div>
 <div className='d-flex flex-column gap-3 gap-md-5'>
  {values.map((value) => (
    <div className='p-2 p-md-3 d-flex align-items-center gap-2 gap-md-4' key={value?.id} style={{boxShadow: '0px 0px 8px 0px #00000080',width:isMobile ? '100%': 'max-content',
    }}>
      <img src={value?.square} alt={value?.title} style={{width:isMobile ?'18px':'36px'}}/>
      <h3 style={{fontSize:isMobile ?'16px':'27px', fontWeight:isMobile?'500':'700', color:'#000B19', textTransform:'uppercase'}} className='my-auto'>{value?.title}</h3>
      <h5 style={{fontSize:isMobile ?'10px':'17px', fontWeight:'400', color:'#000B19'}} className='my-auto'>{value?.text}</h5>
    
    </div>
  ))}
 </div>
 </section>
 
      </div>
      <section style={{backgroundColor:'#E6F1FF'}} className='py-2 d-flex justify-content-center'>
  <img src={isMobile ? npaMobile : npa} alt='authority' className=' pe-2 pe-md-4 authority'/>
  <img src={isMobile ? nimasaMobile : nimasa} alt='authority' className=' pe-2 pe-md-4 authority'/>

  <img src={isMobile ? nuprcMobile : nuprc} alt='authority' className='authority'  />

  <img src={isMobile ? nmdpraMobile : nmdpra} alt='authority' className='authority' />

 </section>
    </div>
  )
}

export default About
