import React from 'react'
import { Card} from 'react-bootstrap'
import footerBg from '../assets/footer-hero.png'
import logo from '../assets/footer-logo.svg'

import linkedIn from '../assets/linkedin.svg'

import { useAppContext } from '../AppContext'


const Footer = () => {

    const {isMobile}= useAppContext();
  return (
    <div  className='d-block w-100 ' >
       
      <Card.ImgOverlay  style={{
      backgroundImage: `url(${footerBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      position: 'relative',
    backgroundColor: 'rgba(1, 62, 144, 0.9)', height:'100%', width:'100'}} >
<div className=' px-2 px-md-5 pt-3 pt-md-5 pb-2 pb-md-4  mx-lg-4' style={{color:'#E6F1FF'}}>
<div className='d-flex justify-content-between mb-4 mb-md-3 align-items-center'>
  <div className='d-flex flex-column gap-1 gap-md-2'>
    
 
    <img src={logo} alt='logo'  style={{width:isMobile?'150px':'250px',height:isMobile?'150px':'240px'}}/>
    <h3 style={{color:'#E6F1FF', fontWeight:'400'}} className='d-none d-md-flex'>FREBLOOM MARINE SOLUTIONS LIMITED</h3>
    </div>

   
    
    <div className=' d-flex flex-column text-align-end justify-content-end mt-0 mt-lg-2 mb-0'>
    <h3 className='footer-text mb-2 mb-md-3'>Providing Solutions, <span style={{fontWeight:'400'}}>Securing the Sea </span></h3>
    <div className='d-flex gap-2 ms-auto mt-0 mt-lg-3 mb-0 mb-md-2'>
      <a href="https://www.linkedin.com/company/frebloom-marine-solutions-limited" target="_blank"  
            rel="noopener noreferrer" >
        <img src={linkedIn} alt='social' style={{width:isMobile?'22px':'48px'}}/></a>
     
       
    </div>
    <div className='border-line ms-auto'>

    </div>
    <a href="mailto:info@frebloommarine.com"  target="_blank"  
            rel="noopener noreferrer" className=' ms-auto' style={{color:'#E6F1FF'}}> <h5  className='ms-auto' style={{fontSize:isMobile && '11px'}}>info@frebloommarine.com</h5></a>
   <a href="tel:+2349010373733" className=' ms-auto' style={{color:'#E6F1FF'}}><h5  className='ms-auto mb-0' style={{fontSize:isMobile && '11px'}}>+234 (0) 90 1037 3733</h5></a> 
    <div className='border-line ms-auto'>

</div>
    <h3  className='ms-auto mt-0 mt-lg-1 mb-0' style={{fontWeight:'400', fontSize:isMobile && '17px'}}>LAGOS, NIGERIA</h3>
   </div>
   </div>
</div>


      </Card.ImgOverlay >
 
     

      <div className=' px-2 px-md-5 pt-2 pb-0 d-block d-md-flex  align-items-center justify-content-md-between w-100' style={{backgroundColor:'#E6F1FF'}}>
        {isMobile ?  <div className='d-flex justify-content-between mx-0 mb-0 pb-1' style={{ fontSize:isMobile? '8px':'11px',color:'#000000'}}>
          <p className='mb-0' style={{fontSize:isMobile? '8px':'11px', color:'#000000'}}>
          • WHISTLEBLOWING AND GRIEVANCE POLICY</p>
          <p className='mb-0' style={{fontSize:isMobile? '8px':'11px', color:'#000000'}}>• PRIVACY STATEMENT</p>
          <p  className='mb-0' style={{fontSize:isMobile? '8px':'11px', color:'#000000'}}>• TERMS & CONDITIONS</p>
        </div>
        :
        <div className='d-flex gap-0 gap-md-3 ps-lg-5' style={{fontSize:isMobile? '9px':'11px', color:'#000000'}}>
            <p  style={{fontSize:isMobile? '9px':'11px', color:'#000000'}}>WHISTLEBLOWING AND GRIEVANCE POLICY</p>
            <p style={{fontSize:isMobile? '9px':'11px', color:'#000000'}}>PRIVACY STATEMENT</p>
            <p style={{fontSize:isMobile? '9px':'11px', color:'#000000'}}>TERMS & CONDITIONS</p>
        </div>}
        <div className='pe-0 me-0 pe-lg-5 d-flex justify-content-start text-start pb-0 '>
        <p style={{fontWeight:'700',fontSize:isMobile? '9px':'11px', color:'#000000'}} className='pb-0'>&copy; FREBLOOM MARINE SOLUTIONS. 2024</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
